
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: "gbgy8mjq",
    dataset: 'production',
    apiVersion: '2022-09-18',
    useCdn: true,
    token: "sk0iokxPXTQdGB8QZ3sDQsgU1V9DtNIMS0VNWAp1Nlo9WBfVIh60OlXTyRNgCY9ZobLIiapnJtJbEqtw5J5PAA6EprrhRp3O8k3zpzlRZAr50CzJ03gcyq5norcN5wASlbENElQfHJ1mdoG2OIZvMfloYbVjQl9MwilcwyNUkkl5U1OZ3vuu"
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);