import React from 'react'
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper'
import { images } from '../../contants'
import { byeBye } from '../../contants/framervariants';
import ReactTooltip from 'react-tooltip';
import Spline from '@splinetool/react-spline';
import './Header.scss'


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  return (
    <div className="app__header app__flex">
      <div className="spline">
      <Spline scene="https://prod.spline.design/KPq2zRTBztdxQH2L/scene.splinecode" />
      </div>
      <div className="app__header-container">
        <div className="app__header-info">
          <motion.div className="app__header-badge app__flex"
            whileInView={{ x: [-200, 0], opacity: [0, 1] }}
            transition={{ duration: 2, ease: "easeInOut" }}
            >
        
            <motion.div className="badge-cmp app__flex" style={{ marginLeft: 20 }}
              whileInView={{ opacity: [0, 1] }}
              transition={{
                duration: 0.3,
                delay: 3.5,
                ease: "easeInOut",
                repeat: Infinity,
                repeatDelay: 1
              }}>
              <p > Hello, I am</p>
              <br />
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.3,
                  delay: 5,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatDelay: 1
                }}>
                <h1 className='head-text'>Leo</h1>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div className="app__header-tag"
            whileInView={{ x: [-200, 0], opacity: [0, 1] }}
            transition={{ delay: 7, duration: 1, ease: "easeInOut" }}>
            <div className="tag-cmp app__flex">
              <p className="p-text">Web Developer</p>
              <p className="p-text">Freelancer</p>
            </div>
          </motion.div>
        </div>
        <motion.div className="app__header-circles"
          variant={scaleVariants}
          whileInView={scaleVariants.whileInView}>
          {[images.react, images.redux, images.node].map((circle, index) => (
            <>
            <div className="circle-cmp app__flex" key={`circle-${circle}`}
            data-tip
            data-for={circle}
            >
              <img src={circle} alt="circle" />
            </div>
            
            </>
            
          ))}
        </motion.div>
      </div>
    </div>
  )
}

export default Header;