import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client';
import { images } from '../../contants';
import './Footer.scss'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: "" })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    setLoading(true);
    
     const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };
    

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  }
  

  return (
    <>

      <h2 className='head-text'>Take a coffee & chat with me</h2>
      <div className="app__footer-cards">
        <div className='app__footer-card'>
          <img src={images.email} alt="email" />
          <a href="mailto:leo@leonardojansen.com" className='p-text'>leo@leo@leonardojansen</a>
        </div>
        <div  className='app__footer-card'>
          <img src={images.mobile} alt="mobile"  />
          <a href="tel: +55 (45) 9992-11778" className='p-text'>+55 (45) 99921-1778</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input  type="text" placeholder='Your Name' name="name" value={name} onChange={handleChangeInput} />
          </div>
          <div className='app__flex'>
            <input  type="email" placeholder='Your E-Mail' name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div className='app__flex'>
            <textarea
             
              placeholder='Your Message'
              value={message}
              name="message"
              onChange={handleChangeInput}
            />

          </div>
          <button type="button" onClick={handleSubmit}>{!loading ? "Send Message" : "Sending..."}</button>
        </div>
      ) : (
        <div>
          <h3>Thank you for getting in touch</h3>
        </div>
      )}


    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'), 'contact', 'app__background3');