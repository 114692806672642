import React from 'react'

const NavigationDots = ({active}) => {
  return (
    <div className="app__navigation">
        {['home', 'about', 'work', 'skills', 'testimonials', 'contact'].map((item, index) => (
           
                  <a href={`#${item}`} 
                  key={item+index} 
                  className="app__navigation-dot" 
                  style={active === item ? {backgroundColor: "rgb(255, 255, 255, 0.6)", boxShadow:"0 0 6px rgba(0,200,0,0.5)"} : { } }/>
                   
                
              ))}
    </div>
  )
}

export default NavigationDots